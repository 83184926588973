.card{
    background: $card-black-background;
    border: 0;
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    box-shadow: $box-shadow;


    label{
      color: rgba($white, 0.6);
    }

    .card-title {
      margin-bottom: .75rem;
    }

    .card-body{
      padding: 15px;

      &.table-full-width{
        padding-left: 0;
        padding-right: 0;
      }

      .card-title{
        color: $white;
        text-transform: inherit;
        font-weight: $font-weight-light;
        margin-bottom: .75rem;
      }

      .card-description, .card-category{
        color: rgba($white, 0.6);
      }

    }

    .card-header{
      &:not([data-background-color]){
        background-color: transparent;
      }
      padding: 15px 15px 0;
      border: 0;
      color: rgba($white,0.8);

      .card-title{
          color: $white;
          font-weight: 100;
      }

      .card-category{
        color: $dark-gray;
        margin-bottom: 5px;
        font-weight: 300;
      }
    }
    
    .selectDown {
      .form-group {
          display: flex; justify-content: flex-start; margin: 0;
          label {
              width: auto;
          }
          select {
              height: 20px; padding: 0; width: auto;
          }
      }
    }

    .view{
      p{
        .oblique {
          font-size: 24px; font-weight: 700; color: #fff;
          small {
              font-size: 12px; margin-left: 5px; opacity: 0.4;
          }
        }
      }
    }

    .map{
        border-radius: $border-radius-sm;

        &.map-big{
          height: 420px;
        }
    }

    &.card-white{
      background: $white;

      .card-title{
        color: $black;
      }
      .card-category, .stats{
        color: $card-stats-gray;
      }

      //style for inputs

      @include form-control-placeholder(rgba($black,0.4), 1);
        .has-danger{
          .form-control, .input-group-prepend .input-group-text{
            border-color: $danger-states;
          }
        }

        .input-group-prepend .input-group-text{
          border-color: rgba($black-states,0.2);
          color: $black-states;
        }

        .form-control{
          color: $black;
          border-color: rgba($black-states,0.2);
          &:focus{
            border-color: $primary;
          }
        }
        label:not(.btn){
          color: $default;
        }

      .form-group.no-border,
      .input-group.no-border {
          .form-control,
          .form-control + .input-group-prepend .input-group-text,
          .form-control + .input-group-append .input-group-text,
          .input-group-prepend .input-group-text,
          .input-group-append .input-group-text{
            background-color: $opacity-gray-3;
              &:focus,
              &:active,
              &:active{
                background-color: $opacity-gray-5;
              }
          }

          .form-control {
              &:focus {
                  & + .input-group-prepend .input-group-text,
                  & + .input-group-append .input-group-text{
                    background-color: $transparent-bg;
                  }
              }
          }


      }

      .input-group[disabled]{
          .input-group-prepend .input-group-text,
          .input-group-append .input-group-text{
              background-color: $black;
          }
      }

      .form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control{
        background: $light-gray;
        border-color: rgba($black-states,0.3);
      }

      .input-group-focus{
          .input-group-prepend .input-group-text,
          .input-group-append .input-group-text,
          .form-control{
            background-color: $white;
            border-color: $primary;
          }

          &.no-border{
              .input-group-prepend .input-group-text,
              .input-group-append .input-group-text{

                  background-color: $opacity-gray-5;
              }
          }
      }
      .input-group-prepend .input-group-text {
          border-right: none;
      }

      .input-group-append .input-group-text {
          border-left: none;
      }

      .has-danger .form-control:focus, .has-success.input-group-focus .input-group-append .input-group-text, .has-success.input-group-focus .input-group-prepend .input-group-text {
        border-color: $danger-states;
      }

      .has-success .form-control:focus, .has-success.input-group-focus .input-group-append .input-group-text, .has-success.input-group-focus .input-group-prepend .input-group-text {
        border-color: darken($success, 10%);
      }
    }


    &.card-plain {
      background: #384562;
      box-shadow: none;
      padding: 5px;
      border-radius: 12px;
      opacity: 1;
      &.sidebar-card.card {
        background: transparent;
      }

      .card-header{
        h4{
          &.card-title {
            font-size: 16px;
            font-weight: 700;
          }
        }
        .view {
          p{
            &.oblique {
              font-size: 24px; color: #fff; font-weight: 700;
              small {
                margin-left: 5px; font-size: 12px; font-weight: 500; letter-spacing: 1px; opacity: 0.6;
              }
            }
          }
        }
      }
    }

    .image{
        overflow: hidden;
        height: 200px;
        position: relative;
    }

    .avatar{
        width: 30px;
        height: 30px;
        overflow: hidden;
        border-radius: 50%;
        margin-bottom: 15px;
    }

    label{
        font-size: $font-size-sm;
        margin-bottom: 5px;

    }

    .card-footer{
        background-color: transparent;
        border: 0;
        padding: 15px;


        .stats{
            i{
                margin-right: 5px;
                position: relative;

            }
        }

        h6{
          margin-bottom: 0;
          padding: 7px 0;
        }
    }
}

.card-body{
  padding: $card-spacer-y;
  .tpf-horizontal-card {
    figure{
      &.figImage {
        width: 50px; height: 50px; overflow: hidden; border-radius: 8px; margin: 0;
      }
    }
    
    .filter {
      .addNew {
        button{
          &.btn {
            font-size: 12px; font-weight: 700; text-transform: uppercase; height: 39px; padding: 0 15px;
            i {
              margin-left: 10px;
            }
          }
        }
      }
    }

    h5{
      &.card-title {
        margin-bottom: 0 !important;
      }
    }
    h4{
      &.card-title {
        margin-bottom: 0;
      }
    }
    p {
      margin-bottom: 0;
    }

    &.with-bg {
      background: #4a5a7c8c; border-radius: 12px;
    }
  }

  .Banner {
    width: 100%; height: 85px; overflow: hidden; border: 2px dashed #ffffff4a; border-radius: 12px;
    display: flex; align-items: center; justify-content: center;
    h4 { text-align: center; margin: 0; }
    figure{
      &.banner-img {
        width: 100%; height: 100%; overflow: hidden; margin: 0;
        img {
          object-fit: cover; width: 100%; height: 100%;
        }
      }
    }
  }
}

.breadCrumb{
  &.card{
    background: transparent;
    .card-body{
      padding: 0;
    }
  }
}
.guide-card{
  &.card {
    background-color: #384562;
    form{
      .form-group {
        .tpfupload {
          width: 100%; height: 250px; border-radius: 12px; border: 2px dashed #ffffff38; position: relative;
          .fileBg {
            width: 100px; height: 100px; margin: 40px auto 20px; position: relative;
            video{
              width: 200px;
              height: 150px;
              overflow: hidden;
            }
            span{
              &.icon {
                display: block; position: absolute; z-index: 1;
              }
            }
            input[type="file"] {
              width: 94px; height: 94px; margin: 0px auto; position: relative;
              top: 0; z-index: 2; border-radius: 50%; cursor: pointer;
            }
          }
          .titleUpload {
            width: 200px; margin: 0 auto;
            h4 {
              font-size: 14px; font-weight: 400; text-align: center; margin: 0;
            }
            p {
              font-size: 12px; font-weight: 500; text-align: center; color: #ffffff8c;
            }
          }
          picture {
            position: absolute; width: 100%; height: 100%; z-index: 3;
            img {
              width: 100%; height: 100%; object-fit: cover;
            }
          }

          button{
            &.btn {
              &.deleteBanner {
                position: absolute; z-index: 4; right: 20px; bottom: 20px;
                i {
                  margin: 0;
                }
              }
            }
          }
        
        }

        label {
          font-size: 14px; font-weight: 700; color: #ffffff87;
        }
        select {
          height: 54px; background: #ffffff14; font-size: 16px; color: #fff; margin-bottom: 0;
        }
        input{
          height: 54px; background: #ffffff14; font-size: 16px; color: #fff; margin-bottom: 0;
          &::placeholder {
            color: #ffffff3d; font-size: 16px; font-weight: 400;
          }
        }
        textarea {
          min-height: 270px !important; background: #ffffff14; margin-bottom: 0;
          &::placeholder {
            color: #ffffff3d; font-size: 16px; font-weight: 400;
          }
        }
      }

      button{
        &.btn {
          font-size: 12px; font-weight: 700; text-transform: uppercase; height: 39px; padding: 0 15px;
          i {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.plans-card{
  &.card {
    background: #ffffff0a; border-radius: 12px;
    .card-header {
      h6 {
        font-size: 14px; font-weight: 500; color: #fff; margin: 0; text-transform: unset;
      }
      h4 {
        font-size: 24px; font-weight: 700; color: #fff; margin: 0;
        small {
          font-size: 12px; color: #ffffff3b;
        }
      }
      hr {
        border-top: 1px solid #ffffff1f; margin: 0;
      }
    }

    .card-body {
      ul{
        &.list {
          margin: 0; padding: 0; list-style: none;
          li{
            &.item {
              display: flex; align-items: flex-start; justify-content: flex-start; 
              margin: 0 0 8px; font-size: 14px; color: #ffffff3d; font-weight: 500;
              span{
                &.icon {
                  margin-right: 5px; width: 25px;
                }
                &.itemText {}
              }
            }
          }
        }
      }
    }
  }
}

.overflow-scroll-vertical{
  overflow-y: scroll;
  overflow-x: hidden;
  height: 280px;
}

.SearchContact {
  background: #d9d9d945;
  border-radius: 12px;
  padding: 5px;
  form {
    input{
      &.form-control {
        border: 0;
        background: transparent;
        height: 100%;
        &::placeholder {
          color: #fff;
          opacity: 0.4;
        }
      }
    }
  }
}

.contactList {
  ul {
    margin: 0; padding: 0; list-style: none; height: 55vh; overflow-y: auto; overflow-x: hidden;
    li {
      .contact {
        display: flex; align-items: center; justify-content: start; padding: 5px;
        cursor: pointer;
        figure {
          width: 40px; height: 40px; overflow: hidden; border-radius: 8px; margin: 0 10px 0 0;
          img {}
        }
        .contactname {
            h4 {
                font-size: 12px; color: #fff; margin: 0;
                span {
                &.badge {
                    margin-left: 5px;
                }
                }
            }
            p {
                font-size: 12px; color: #ffffff4f; margin: 0;
            }
        }
      }

      &.active {
        .contact {
          background: #d9d9d92e; border-radius: 6px;
        }
      }
    }
  }
}

.chatWindow {
  background: #d9d9d929; border-radius: 6px;
  .cw-header {
    display: flex; justify-content: space-between; align-items: center; 
    padding: 15px; border-bottom: 1px solid #ffffff1f;
    figure {
      width: 48px; height: 48px; overflow: hidden; border-radius: 8px; margin: 0;
      img {
        object-fit: cover; width: 100%; height: 100%;
      }
    }
    button{
      &.btn { height: 40px; }
    }
    .infoColumn {
      label {
        color: #fff; opacity: 0.6; margin: 0;
      }
      a {
        color: #fff; font-size: 14px; margin: 0;
      }
    }
  }

  .cw-body {
    padding: 15px;

    .messageWindow {
      height: 44vh; min-height: 240px;
        overflow-y: auto;
      .messageTo {
        margin-bottom: 15px;
        .messageBody {
          h4 {
            font-size: 14px; font-weight: 700; margin-bottom: 5px;
            span{
              &.time {
                color: #ffffff45; margin-left: 10px; font-weight: 400;
              }
            }
          }
          .messageHere {
            background: #ffffff1c; display: inline-block; padding: 8px 12px; 
            border-radius: 0px 12px 12px 12px;
            p {
              font-size: 14px; font-weight: 400; color: #fff;
            }
          }
        }
      }

      .messageFrom {
        .messageBody {
          h4 {
            text-align: right; font-size: 14px;
            font-weight: 700; margin-bottom: 5px; width: 100%;
            span{
              &.time {
                margin-right: 10px; color: #ffffff45; margin-left: 10px; font-weight: 400;
              }
            }
          }
          .messageHere {
            background: #375297; display: inline-block; padding: 8px 12px;
            border-radius: 0px 12px 12px 12px;
            p {
              font-size: 14px; font-weight: 400; color: #fff;
            }
          }
        }
      }
    }
    
    .meeseageSend {
      form {
        display: flex; justify-content: space-between; align-items: center;
        input.form-control {
          width: 100%; margin-right: 8px;
          height: 44px; background: #ffffff1a; border: 0; border-radius: 12px;
          &::placeholder {
            color: #fff6;
          }
        }

        button.btn {
          width: 44px; height: 44px; margin: 0; border-radius: 12px; padding: 0;
          background: linear-gradient(237deg, #4F76D9 -24.53%, #000000 119.86%);
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .card.card-chart .card-header {
    .btn-group-toggle .tim-icons {
      font-size: .875rem;
      top: -1px;
    }
  }
}

//
// Rotating border
//

@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border {
  display: inline-block;
  width: $spinner-width;
  height: $spinner-height;
  vertical-align: text-bottom;
  border: $spinner-border-width solid currentColor;
  border-right-color: transparent;
  // stylelint-disable-next-line property-disallowed-list
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: $spinner-width-sm;
  height: $spinner-height-sm;
  border-width: $spinner-border-width-sm;
}

//
// Growing circle
//

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: $spinner-width;
  height: $spinner-height;
  vertical-align: text-bottom;
  background-color: currentColor;
  // stylelint-disable-next-line property-disallowed-list
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
  width: $spinner-width-sm;
  height: $spinner-height-sm;
}
