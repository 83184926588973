.login-wrapper {
    background-image: url(../img/login-bg.jpg);
    background-position: right bottom;
    background-size: cover;
    height: 100vh;
    background-repeat: no-repeat;
    overflow: hidden;

    .login-panel {
        height: 100vh; background-color: #1A2336; position: relative; overflow: hidden;
        padding: 80px 80px 0; display: flex;  flex-wrap: wrap; align-items: center; border-radius: 0px 30px 30px 0px;
        &:before {
            content: "";
            background-image: radial-gradient(115.47% 100% at 50.13% 0%, #22304E 0%, rgba(13, 17, 25, 0) 100%);
            height: 50%; width: 100%; position: absolute; z-index: 1; left: 0; top: 0;
        }
        .card {
            background: transparent; position: relative; z-index: 1;
            .card-header {
                padding: 0;
            }
            .card-body{
                padding: 0;
                h3{
                    &.card-title{
                        font-weight: 700; font-size: 28px;
                    }
                }
                h4{
                    &.card-subtitle{
                        font-size: 14px; font-weight: 500; color: #fff; opacity: .6;
                        width: 95%; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;
                    }
                }
                figure {
                    margin: 0; text-align: center;
                }
                form {
                    .form-group {
                        input {
                            font-size: 16px; font-weight: 400; color: #ffffff40;
                            background: #ffffff12; height: 54px; width: 100%; padding: 0 15px;
                            &:focus {
                                background: #000; opacity: 1; color: #ffff; outline: 1px solid #61A1F8;
                            }
                        }
                        label {
                            font-size: 14px; font-weight: 700; color: #fff; opacity: 0.5;
                        }
                        .visibleEye {
                            // width: 50px; 
                            display: flex; align-items: center;
                            justify-content: space-between; position: absolute; right: 15px; top: 40px;
                        }
                        .forgot {
                            display: flex;justify-content: flex-end;
                            a {
                                color: #fff; font-size: 15px; text-transform: uppercase; text-decoration: none; font-weight: 500;
                            }
                        }
                    }
                    button.btn {
                        background: linear-gradient(237deg, #4F76D9 -24.53%, #000000 119.86%);
                        width: 100%; font-size: 16px; font-weight: 500; text-transform: uppercase;
                    }
                }
            }
        }

        .powered {
            width: 100%;display: block;
            h5 {
                font-size: 14px; font-weight: 400; text-align: center; margin: 0;
            }
            p {
                text-align: center; font-size: 14px; font-weight: 400; opacity: 0.6;
            }
        }
    }
}

